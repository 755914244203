.logo {
  width: 20%;
  margin-top: 5%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.gif {
  width: 45%;
  margin-top: 8%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
